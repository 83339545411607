import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'
import readingTime from 'reading-time'
import jump from 'jump.js'

import { toPlainText } from 'src/utils/utils'
import { EditorialSectionSerializer } from 'src/utils/editorialSerializer'
import { Image } from 'src/components/image'

import { Twitter, Mail, UpArrow, Facebook } from 'src/components/svgs'
import { push } from 'gatsby'

export const EditorialInfo = ({
  authors,
  slug,
  title,
  editorialText,
}: {
  authors: any[]
  slug: {
    current: string
  }
  title: string
  editorialText: any[]
}) => {
  const stats = readingTime(toPlainText(editorialText))
  const [box, setBox] = useState(0)
  const [bottom, setBottom] = useState('100vh')
  const ref = useRef(null)
  useEffect(() => {
    setTimeout(() => {
      setBox(ref.current.getBoundingClientRect())
    }, 2000)
  }, [0])

  useEffect(() => {
    setTimeout(() => {
      const sharable = require('sharable/browser')
      // eslint-disable-next-line
      const share = sharable.default()
    }, 300)
  }, [0])

  const push = (direction: string) => {
    switch (direction) {
      case 'down':
        setBottom(`calc(100vh - ${box.height}px)`)
        break
      case 'up':
        setBottom('100vh')
        break
    }
  }

  return (
    <div
      className="editorial__info rel br cb z1 bcw p1 m1"
      style={
        {
          // transform: `translateY(${box.height > 0 && box.height}px)`
        }
      }
    >
      <div>
        {authors?.map(author => (
          <div className="editorial__author f jcs aic" key={author.id}>
            <div className="editorial__author-image mr1 rel">
              <Image
                className="x abs y"
                imageId={author.content.main.image.asset._id}
                alt={author.content.main.title}
              />
            </div>
            <p className="m0 p0 ss15">
              <span className="bold">By {author.content.main.title}</span>
              <br />
              <span className="ss12 sm15">{author.content.main.position}</span>
            </p>
          </div>
        ))}
      </div>
      <div className="editorial__reading karla ss15 py1">{stats.text}</div>
      <div className="ss15 js-editorial-info">
        <BlockContent
          blocks={editorialText}
          serializers={EditorialSectionSerializer}
        />
      </div>
      <div className="editorial__share pt1 f jcb aic">
        <button
          onClick={() => jump('.js-editorial', { offset: -200 })}
          className="no-style editorial__back cursor ss15 f jcs aic cpink"
        >
          <UpArrow className="mr05" />{' '}
          <span className="underline karla ss15">Back to Top</span>
        </button>
        <div className="cb editorial__social">
          <a
            href="#0"
            data-social="twitter"
            className=""
            data-url={`https://www.asktia.com/article/${slug.current}`}
          >
            <Twitter />
          </a>
          <a
            href="#0"
            data-social="facebook"
            className=""
            data-url={`https://www.asktia.com/article/${slug.current}`}
          >
            <Facebook />
          </a>
          <a
            href={`mailto:?subject=${title}&body=https://www.asktia.com/article/${slug.current}`}
            className=""
          >
            <Mail />
          </a>
        </div>
      </div>
      <div
        className="editorial__share-mobile pt1"
        style={{ bottom: `${bottom}` }}
        onMouseEnter={() => push('down')}
        onMouseLeave={() => push('up')}
      >
        <div className="ss15 js-editorial-info px1" ref={ref}>
          <BlockContent
            blocks={editorialText}
            serializers={EditorialSectionSerializer}
          />
        </div>
        <div className="p1 f jcb aic">
          <button
            onClick={() => jump('.js-editorial', { offset: -600 })}
            className="no-style editorial__back cursor ss15 f jcs aic cpink"
          >
            <UpArrow className="mr05" />{' '}
            <span className="underline karla ss15">Back to Top</span>
          </button>
          <div className="cb editorial__social">
            <a
              href="#0"
              data-social="twitter"
              className=""
              data-url={`https://www.asktia.com/article/${slug.current}`}
            >
              <Twitter />
            </a>
            <a
              href="#0"
              data-social="facebook"
              className=""
              data-url={`https://www.asktia.com/article/${slug.current}`}
            >
              <Facebook />
            </a>
            <a
              href={`mailto:?subject=${title}&body=https://www.asktia.com/article/${slug.current}`}
              className=""
            >
              <Mail />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
