import React, { useEffect } from 'react'
import cx from 'classnames'

import { Image } from 'src/components/image'
import { ShapeThree } from 'src/components/svgs'

import { EditorialInfo } from 'src/components/editorial/editorialInfo'

export interface GuideHeaderProps {
  title: string
  subTitle: string
  topic: {
    title: string
  }
  slug: {
    current: string
  }
  articleColor: string
  authors: any[]
  image: {
    asset: {
      _id: string
    }
  }
}

export const GuideHeader = ({
  title,
  subTitle,
  slug,
  articleType,
  image,
  editorialText,
  authors,
  articleColor,
}: GuideHeaderProps) => {
  useEffect(() => {
    const sharable = require('sharable/browser')
    // eslint-disable-next-line
    const share = sharable.default()
  }, [0])
  return (
    <div className="editorial__guide">
      <div className={cx('rel editorial__header')}>
        <Image className="abs x y" imageId={image.asset._id} alt={title} />
        <div className="abs x y top left z1 f jcc aic ac">
          <div className="p1">
            {articleType && <h5 className="caps mono">{articleType.title}</h5>}
            <h1 className="my1">{title}</h1>
            <h3>{subTitle}</h3>
          </div>
        </div>
        <ShapeThree className="abs cpurple editorial__wave bottom z2 x left" />
      </div>
      <div className="container--l editorial__header-container mxa rel f jce">
        <div
          className={cx('x rel editorial__header-flex jcb ais', articleColor)}
        >
          <EditorialInfo
            title={title}
            authors={authors}
            slug={slug}
            editorialText={editorialText}
          />
        </div>
      </div>
    </div>
  )
}
