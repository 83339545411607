import React, { useEffect } from 'react'
import cx from 'classnames'

import { Image } from 'src/components/image'
import { ShapeThree } from 'src/components/svgs'

import { EditorialInfo } from 'src/components/editorial/editorialInfo'

export interface EditorialHeaderProps {
  title: string
  subTitle: string
  topic: {
    title: string
  }
  slug: {
    current: string
  }
  articleType: {
    title: string
  }
  cardImage?: {
    asset: {
      _id: string
    }
  }
  editorialText: any[]
  articleColor: string
  authors: any[]
  image: {
    asset: {
      _id: string
    }
  }
}

export const EditorialHeader = ({
  title,
  subTitle,
  slug,
  articleType,
  image,
  cardImage,
  editorialText,
  authors,
  articleColor,
}: EditorialHeaderProps) => {
  useEffect(() => {
    const sharable = require('sharable/browser')
    // eslint-disable-next-line
    const share = sharable.default()
  }, [0])
  return (
    <div className={articleColor}>
      <div className={cx('rel editorial__header', articleColor)}>
        {cardImage && (
          <Image
            className="x editorial__header-mobile"
            imageId={cardImage.asset._id}
            raw={cardImage}
            size={800}
            alt={title}
          />
        )}
        <Image
          className={cx('x', cardImage && 'editorial__header-desktop')}
          imageId={image.asset._id}
          alt={title}
        />
        <ShapeThree className="abs editorial__wave bottom z2 x left" />
      </div>
      <div className="container--l editorial__header-container mxa rel f jce">
        <div
          className={cx(
            'x rel editorial__header-flex jcb cw ais',
            articleColor
          )}
        >
          <div className={cx('x rel editorial__header-inner')}>
            <div className="">
              <div className="p1">
                {articleType && (
                  <h5 className="caps mono">{articleType.title}</h5>
                )}
                <h1 className="m0 p0">{title}</h1>
                <h3>{subTitle}</h3>
              </div>
            </div>
          </div>
          <EditorialInfo
            title={title}
            authors={authors}
            slug={slug}
            editorialText={editorialText}
          />
        </div>
      </div>
    </div>
  )
}
