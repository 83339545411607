import React from 'react'
import { Script } from "gatsby";
import { toPlainText } from 'src/utils/utils'

export const Schema = ({
  pagePath,
  title,
  publishedAt,
  image,
  editorialText,
}: {
  pagePath: string
  title: string
  publishedAt: any
  image: {
    asset: {
      url: string
    }
  }
  editorialText: any[]
}) => {
  const schema = {
    '@context': 'http://schema.org/',
    '@type': 'Article',
    author: {
      '@type': 'Organization',
      name: 'Tia',
    },
    headline: title,
    datePublished: publishedAt,
    url: pagePath,
    image: image.asset.url,
    articleBody: toPlainText(editorialText),
    publisher: {
      '@type': 'Organization',
      name: 'Tia',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.asktia.com/static/tia-logo.png',
      },
    },
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': pagePath,
    },
  }

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
        <Script  type="application/ld+json">
          ${JSON.stringify(schema)}
        </Script >
      `,
      }}
    />
  )
}
